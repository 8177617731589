import React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import Jump from 'react-reveal/Jump';

import * as styles from '../styles/flex/other/about.module.css';

function About() {
    return (
        <Layout>
            <Seo title="About" description="A description of where the inspiration to build this website came from." />
            <Jump>
                <section className={styles.AboutContainer}>
                    <div className={styles.AboutSection}>
                        <div className={styles.InnerContainer}>
                            <h1>About</h1>
                            <p className={styles.Text}>
                                This website was created solely as a result of a conversation
                                I had with a friend one night while playing GTA Online. We had 
                                spent the night grinding for money to be ready for the next big
                                update and I had been searching for stats on the bunker to try 
                                and estimate how long it would take me to make a certain amount 
                                of money.
                                <br/><br/>
                                After a while of searching I said it would be nice if there was 
                                a tool that presented information in a more enjoyable way than 
                                looking through tables. He responded with, "Yeah that would be a 
                                cool website." Having been recently introduced to web development 
                                at the time, I saw this as the prefect side project to work on and 
                                learn from.
                                <br/><br/>
                                So here we are!
                            </p>
                        </div>
                    </div>
                </section>
            </Jump>
        </Layout>
    )
}

export default About;